<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col-6">
				<div class="d-flex h-100">
					<b-button
						v-if="!showAddCredit"
						:variant="!showFilters ? 'primary' : 'secondary'"
						class="btn mx-1 bg-primary-alt mr-auto"
						@click="showFilters = !showFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</b-button>
				</div>
			</div>
			<div class="col-6">
				<div
					v-if="!showAddCredit && $can('points', 'add')"
					class="d-flex h-100">
					<b-button
						variant="primary"
						class="btn ml-auto mr-0"
						@click="showAddCredit = !showAddCredit">
						{{ translate('add_points') }}
					</b-button>
				</div>
			</div>
		</div>
		<div v-if="!showAddCredit">
			<hr>
			<data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="clearFilters">
				<template slot="form">
					<div class="row">
						<div class="col-sm">
							<div
								class="form-group">
								<label
									for="dateRange"
									class="label">{{ translate('date_range') }}</label>
								<select
									id="dateRange"
									v-model="dateRange"
									name="dateRange"
									class="form-control">
									<option
										:value=" '' ">
										{{ translate('all') }}
									</option>
									<option
										v-for="dateRange in dateRanges"
										:key="dateRange"
										:value="dateRange">
										{{ translate(dateRange) }}
									</option>
								</select>
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col-sm">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endDate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</template>
			</data-filter>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								#
							</th>
							<th
								v-if="isAdminOrCorporate"
								class="border-top-0 pointer"
								@click="sortByField('user_id')">
								{{ translate('user_id') }}
								<sort field="user_id" />
							</th>
							<th
								v-if="isAdminOrCorporate"
								class="border-top-0 pointer"
								@click="sortByField('username')">
								{{ translate('username') }}
								<sort field="username" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('created_at')">
								{{ translate('date') }}
								<sort field="created_at" />
							</th>
							<th class="border-top-0">
								{{ translate('description') }}
							</th>
							<th class="border-top-0 text-right">
								{{ translate('credit') }}
							</th>
							<th class="border-top-0 text-right">
								{{ translate('debit') }}
							</th>
							<th class="border-top-0">
								{{ translate('order_id') }}
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('from_user.id')">
								{{ translate('from_user_id') }}
								<sort field="from_user.id" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('from_user.username')">
								{{ translate('from_username') }}
								<sort field="from_user.username" />
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td class="align-middle">
								{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
							</td>
							<td
								v-if="isAdminOrCorporate"
								class="align-middle">
								<span
									v-if="item.attributes.user.type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.user.id.toString() })">
									{{ item.attributes.user.id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.user.id }}
								</span>
							</td>
							<td
								v-if="isAdminOrCorporate"
								class="align-middle">
								{{ item.attributes.user.username }}
							</td>
							<td class="align-middle">
								{{ $moment(item.attributes.created_at.date).format(isAdminOrCorporate ? dateTimeFormat : dateFormat) }}
							</td>
							<td class="align-middle white-space-normal width-custom">
								{{ item.attributes.description }}
							</td>
							<td class="align-middle text-right">
								<span v-if="item.attributes.amount_value >= 0">
									{{ item.attributes.amount }}
								</span>
							</td>
							<td class="align-middle text-right">
								<span v-if="item.attributes.amount_value < 0">
									({{ item.attributes.amount }})
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.order_id }}
							</td>
							<td class="align-middle">
								<span
									v-if="isAdminOrCorporate && item.attributes.from_user.type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.from_user.id.toString() })">
									{{ item.attributes.from_user.id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.from_user.id }}
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.from_user.username }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
			<b-pagination
				v-if="pagination.total_pages > 1"
				v-model="pagination.current_page"
				:total-rows="pagination.total"
				:per-page="pagination.per_page"
				align="center"
				@change="getDataFiltered" />
		</div>
		<div v-else>
			<form
				@submit.prevent="addCredits"
				@keydown="clearError($event.target.name)"
				@change="clearError($event.target.name)">
				<div class="row">
					<div class="col-12 col-md-4">
						<b-form-group>
							<search
								v-model="form.user_id"
								name-value="user_id"
								:endpoint="endpoint"
								:label-text="`${translate('autocomplete_label')}`"
								:empty-result-message="translate('data_not_found')"
								:placeholder="'autocomplete_placeholder'"
								:default-params="{role:'distributor,customer,affiliate'}"
								:required="true"
								:custom-error="hasError('user_id') ? errors['user_id'][0] : false"
								class="userSearch"
								q="label"
								return-key="id" />
						</b-form-group>
					</div>
					<div class="col-12 col-md-4">
						<b-form-group>
							<label
								for="amount"
								class="label">
								{{ translate('amount') }}
								<span class="text-danger">*</span>
							</label>
							<input
								id="amount"
								v-model="form.amount"
								:class="hasError('amount') ? 'is-invalid' : ''"
								name="amount"
								type="number"
								class="form-control"
								aria-describedby="amount"
								:placeholder="translate('amount')"
								@dataChanged="amount = $event">
							<template v-if="hasError('amount')">
								<span
									v-for="error in errors['amount']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</div>
					<div class="col-12 col-md-4">
						<b-form-group>
							<label
								for="order"
								class="label">
								{{ translate('order_id') }}
							</label>
							<input
								id="order"
								v-model="form.order_id"
								:class="hasError('order_id') ? 'is-invalid' : ''"
								:placeholder="translate('order_id')"
								name="order_id"
								type="number"
								class="form-control"
								aria-describedby="order"
								@dataChanged="order_id = $event">
							<template v-if="hasError('order_id')">
								<span
									v-for="error in errors['order_id']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<b-form-group>
							<label
								for="description"
								class="label">
								{{ translate('detail') }}
							</label>
							<b-form-textarea
								id="description"
								v-model="form.description"
								:placeholder="translate('detail')"
								:class="hasError('description') ? 'is-invalid' : ''"
								maxlength="190"
								name="description"
								no-resize
								@dataChanged="description = $event" />
							<template v-if="hasError('description')">
								<span
									v-for="error in errors['description']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</div>
				</div>
			</form>
			<div class="row mt-3">
				<div class="col">
					<div class="d-flex h-100">
						<b-button
							class="btn ml-auto mr-0"
							@click="goBack()">
							{{ translate('cancel') }}
						</b-button>
						<b-button
							:disabled="Object.keys(errors).length > 0"
							variant="primary"
							class="btn ml-2"
							@click="addCredits()">
							{{ translate('add') }}
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { admin, distributor } from '@/settings/Roles';
import {
	DATE_RANGES as dateRanges, V_CALENDAR_CONFIG, YMD_FORMAT, YMDHMS_FORMAT,
} from '@/settings/Dates';
import {
	Grids, Wallets as WalletMessages,
} from '@/translations';
import { PAGINATION } from '@/settings/RequestReply';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import DataFilter from '@/components/DataFilter';
import Wallets from '@/util/Wallets';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Points',
	messages: [Grids, WalletMessages],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			admin,
			distributor,
			calendarConfig: V_CALENDAR_CONFIG,
			dateFormat: YMD_FORMAT,
			dateTimeFormat: YMDHMS_FORMAT,
			dateRanges,
			pointsTransactions: new Wallets(),
			points: new Wallets(),
			alert: new this.$Alert(),
			endpoint,
			showAddCredit: false,
			form: {
				user_id: '',
				description: '',
				amount: '',
				order_id: '',
			},
		};
	},
	computed: {
		loading() {
			return !!this.pointsTransactions.data.loading;
		},
		pagination() {
			return this.pointsTransactions.data.pagination;
		},
		data() {
			try {
				const { data } = this.pointsTransactions.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			if (this.data) {
				const response = this.data.length;
				return !!response;
			}
			return false;
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		errors() {
			try {
				return this.points.data.errors.errors;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		'form.user_id': function user() {
			if (this.points.data.errors.errors.user_id) this.clearError('user_id');
		},
	},
	created() {
		this.pointsTransactions.getPointsTransactions();
	},
	methods: {
		goBack() {
			this.clearForm();
			this.clearError('password');
			this.showAddCredit = !this.showAddCredit;
		},
		clearError(field) {
			if (field) {
				delete this.points.data.errors.errors[field];
				this.points.data.errors.errors = { ...this.errors };
			}
		},
		hasError(field) {
			if (typeof this.points.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		clearForm() {
			Object.keys(this.form).forEach((item) => {
				this.form[item] = '';
				this.clearError(item);
			});
		},
		addCredits() {
			const trans = {
				title: this.translate('add_points'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};
			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
					user_id: this.form.user_id,
					amount: this.form.amount,
				};
				if (this.form.order_id) payload.order_id = this.form.order_id;
				if (this.form.description) payload.description = this.form.description;
				this.points.addPoints(payload).then(() => {
					this.alert.toast('success', this.translate('points_added'));
					this.clearForm();
					this.showAddCredit = !this.showAddCredit;
					this.pointsTransactions.getPointsTransactions();
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						this.clearError('password');
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.pointsTransactions.getPointsTransactions(options);
		},
	},
};
</script>
<style>
	.userSearch .form-group.relative{
		margin-bottom: 0px;
	}
	.width-custom{
		max-width: 30em;
		min-width: 20em;
	}
</style>
